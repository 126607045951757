@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --bs-primary: rgba(4,95,105,1)
}

.typing::before {
    content: '';
    width: 2px;
    height: 100%;
    background: #4FC3F7;
    animation: cursorBlink 0.8s steps(3) infinite;
}

@keyframes cursorBlink {

    0%,
    75% {
        opacity: 1;
    }

    76%,
    100% {
        opacity: 0;
    }
}

.typing {
    -webkit-box-reflect: below 1px linear-gradient(transparent, #3333);
}

.typing {
    text-align: center;
    color: #4FC3F7;
    letter-spacing: 5px;
    font-size: 4rem;
    overflow: hidden;
    margin-bottom: 0;
    animation: type 5s steps(11) infinite;
}

@keyframes type {

    0%,
    100% {
        width: 0px;
    }

    30%,
    60% {
        width: 394.09px;
    }
}

html, body {
    background-color: white;
    transition: 0.2s;
    font-family: "Poppins", sans-serif !important;
}

main {
    background-color: white;
}

.bg-custom-primary,
.bg-custom-primary:active,
.bg-custom-primary:hover {
    /* background-color: #7e22ce; */
    background-color: rgb(4,95,105);
    /* background: linear-gradient(90deg, rgba(4,95,105,1) 0%, rgba(14,113,125,1) 100%); */
}

.bg-custom-secondary {
    background-color: #ffffff;
    border-width: 1px;
    border-color: rgba(4,95,105,1);
    color: rgba(4,95,105,1)
}

.bg-custom-primary:hover {
    opacity: 0.8;
}

.icon-primary {
    color: rgba(4,95,105,1);
}

.font-small {
    font-size: x-small;
}

.font-regular {
    font-size: 12px;
}

.page-content {
    overflow-x: hidden;
    height: 100vh;
    transition: all 300ms ease;
    z-index: 90;
    /* padding-bottom: 80px; */
    /* padding-bottom: calc(80px + (constant(safe-area-inset-bottom))*1.1); */
    padding-bottom: calc(80px + (env(safe-area-inset-bottom)) * 1.1);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#footer-bar {
    height: calc(60px + (constant(safe-area-inset-bottom)) * 1.1);
    height: calc(60px + (env(safe-area-inset-bottom)) * 1.1);
}

#footer-bar {
    /* overflow-y: scroll; */
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 98;
    background-color: rgba(249,249,249,.98);
    box-shadow: 0 -5px 10px 0 rgba(0,0,0,.06);
    min-height: (env(safe-area-inset-bottom));
    display: flex;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: all 300ms ease;
}

.font-100 {
    font-weight: 100;
}

.font-200 {
    font-weight: 200;
}

.font-300 {
    font-weight: 300;
}

.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.text-dark-grey {
    color: #bababa !important;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: #1f1f1f;
    font-family: "Poppins",sans-serif !important;
    margin-bottom: 5px;
    letter-spacing: -0.8px;
}

.card-style {
    overflow: hidden;
    margin: 0px 15px 30px 15px;
    border-radius: 20px;
    border: none;
    box-shadow: 0 4px 24px 0 rgba(0,0,0,.1);
}

.x-stop {
    overflow-y: scroll; 
    overflow-x: hidden;
}

.text-100 {
    font-size: 0.6rem;
}

.text-150 {
    font-size: 0.7rem;
}

.text-200 {
    font-size: 0.8rem;
}

.text-300 {
    font-size: 1.0rem;
}

.text-400 {
    font-size: 1.2rem;
}

.text-500 {
    font-size: 1.3rem;
}

.text-600 {
    font-size: 1.5rem;
}

.swiper {
    width: 100%;
    height: 100%;
}
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
}

.gambar {
    object-fit: fill;
    height: 60vh;
    max-height: 650px;
}

.floating-search {
    z-index: 1;
    width: 1140px;
    max-width: 100%;
    background-color: #fff;
    border: 1px solid #f4f5f7;
    box-shadow: 0 6px 16px rgba(0,0,0,.05);
    position: relative;
    border-radius: 8px;
    margin: auto;
    margin-top: -20vh;
    padding: 24px;
}

.floating-search-normal {
    z-index: 1;
    width: 1140px;
    max-width: 100%;
    position: relative;
    border-radius: 8px;
    margin: auto;
    margin-top: -62px;
}

.swiper-responsive {
    display: block;
}

.swiper-main {
    display: block;
}

.swiper-promotion {
    display: block;
}

.swiper-promotion-mobile {
    display: block;
}

.swiper-promotion-mobile .swiper .swiper-button-next,
.swiper-promotion-mobile .swiper .swiper-button-prev {
    display: none;
}

/* .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
} */

footer .list-group li, footer .list-group a {
    font-size: .875rem;
}

footer .list-group a {
    color: #515151;
    line-height: 24px;
}

a {
    text-decoration: none;
    background-color: transparent;
}

.line-red {
    border-top: 4px solid #d44587;
    width: 5%;
    width: 44px;
    margin: auto;
}

hr {
    margin-top: 2rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

.review-title {
    color: #1f1f1f;
}

a {
    color: #1f1f1f;
}

.global-search {
    display: block;
}

.reponsive-image {
    padding-top: 60px;
    padding-bottom: 30px;
}

.section-container {
    max-width: 1184px; 
    justify-content: center;
    align-items: center;
    margin: auto;
}

.div-container {
    max-width: 1140px;
    margin: auto;
}

.mobile-responsive-container {
    padding: 0;
}

.small-line-height {
    line-height: 1rem;
}

.card-overlay .h-img-overlay {
    object-fit: cover;
    height: 150px;
}

.card-overlay .img-overlay {
    transition: all .3s ease;
}

.text-yellow, .star-yellow i {
    color: #f4c433;
}

.swiper-container {
    width: 480px;
}

section .services, section .areas {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.page_wrapper.page_new_wrapper .badge {
    border-radius: 3px;
}

section .services .badge, section .areas .badge {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #f4f5f7;
    padding: 7px 9px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.page_wrapper.page_new_wrapper .albums .row.data-albums {
    display: flex;
    flex-wrap: wrap;
}

.album_size {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #464646;
    background: rgba(255,255,255,.9);
    border-radius: 4px;
    padding: 5px 8.5px;
}

.login-container {
    display: flex;
    height: '100vh' !important;
    justify-content: center;
    align-items: center;
}

.register-container {
    display: flex;
    height: '100vh' !important;
    justify-content: center;
}

.cbtn {
    font-family: "Poppins", sans-serif !important;
    font-size: 0.8rem;
    transition: 1s;
}

.cbtn-active {
    font-family: "Poppins", sans-serif !important;
    font-size: 0.8rem;
    color: white;
    background: rgba(4,95,105,1);
    transition: 0.2s;
}

.cbtn-active span {
    color: white;
}

.cbtn-active:active {
    font-family: "Poppins", sans-serif !important;
    font-size: 0.8rem;
    color: white;
    background: rgba(4,95,105,1) !important;
    transition: 1s;
}

.cbtn-active:hover {
    font-family: "Poppins", sans-serif !important;
    font-size: 0.8rem;
    color: white;
    background: rgba(4,95,105,1) !important;
    transition: 1s;
}

.cbtn-active::after {
    font-family: "Poppins", sans-serif !important;
    font-size: 0.8rem;
    color: white;
    background: rgba(4,95,105,1) !important;
    transition: 1s;
}

.jumbotron {
    background-size: contain !important;
}

@media only screen and (min-width: 200px) and (max-width: 480px) {

    .jumbotron {
        background-size: cover !important;
    }

    .floating-search {
        margin-top: -20vh;
    }

    .login-container {
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .mobile-padding {
        padding: 20px;
    }

    .gambar {
        height: 23vh;
        object-fit: fill;
    }

    .global-search {
        display: none;
    }

    .mobile-responsive-container {
        padding: 1px;
    }

    .p-xs-2 {
        padding: 16px;
    }

    .hide-on-mobile {
        display: none;
    }

    .show-on-mobile {
        display: block;
    }

    .reponsive-image {
        padding-top: 0;
        padding-bottom: 0;
    }

    .swiper-promotion, .swiper-main {
        display: none;
    }

    .swiper-promotion-mobile {
        display: block;
    }
}

@media only screen and (max-width: 575px) {

    .jumbotron {
        background-size: contain !important;
    }

    .global-search {
        display: none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 785px) {

    .jumbotron {
        background-size: contain !important;
    }

    .mobile-padding {
        padding: 16px;
    }

    .gambar {
        height: 23vh;
        object-fit: fill;
    }

    .floating-search {
        margin-top: -28px;
    }

    .swiper-main {
        display: none !important;
    }

    .swiper-responsive {
        display: block;
    }

    .swiper-promotion {
        display: none;
    }

    .swiper-promotion-mobile {
        display: block;
    }

    .floating-search-normal {
        padding: 24px;
    }

    .reponsive-image {
        padding-top: 0;
        padding-bottom: 0;
    }

    .mobile-responsive-container {
        padding: 1px;
    }

    .p-xs-2 {
        padding: 16px;
    }

    .hide-on-mobile {
        display: none;
    }

    .show-on-mobile {
        display: block !important;
    }
}

select {
    font-family: "Poppins", sans-serif !important;
}

@media only screen and (min-width: 786px) and (max-width: 1024px) {

    .jumbotron {
        background-size: contain !important;
    }

    .mobile-padding {
        padding: 0;
    }

    .gambar {
        height: 30vh;
        object-fit: contain;
    }

    .floating-search {
        margin-top: 0;
    }

    .swiper-main {
        display: block;
    }

    .swiper-responsive {
        display: none;
    }

    .swiper-promotion {
        display: block;
    }

    .swiper-promotion-mobile {
        display: none;
    }

    .floating-search-normal {
        padding: 24px;
    }

    .show-on-mobile {
        display: none;
    }
}

@media only screen and (min-width: 1024px) {

    .jumbotron {
        background-size: contain !important;
    }

    .gambar {
        /* height: ; */
        max-height: 400px;
        width: auto;
    }

    .floating-search {
        margin-top: -15vh;
    }

    .swiper-main {
        display: block;
    }

    .swiper-responsive {
        display: none;
    }

    .swiper-promotion {
        display: block;
    }

    .swiper-promotion-mobile {
        display: none;
    }

    .show-on-mobile {
        display: none;
    }
}

@media only screen and (min-width: 1441px) {
    .jumbotron {
        background-size: contain !important;
    }
}

.timeline {
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 5rem;
    margin: 0 auto 1rem auto;
    overflow: hidden;
}
.timeline:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    border-right: 2px dashed #4b546f;
    height: 100%;
    display: block;
}

.timeline-row {
    padding-left: 50%;
    position: relative;
    margin-bottom: 30px;
}
.timeline-row .timeline-time {
    position: absolute;
    right: 50%;
    top: 15px;
    text-align: right;
    margin-right: 20px;
    color: #bcd0f7;
    font-size: 1.5rem;
}
.timeline-row .timeline-time small {
    display: block;
    font-size: 0.8rem;
}
.timeline-row .timeline-content {
    position: relative;
    padding: 20px 30px;
    background: #1a233a;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.timeline-row .timeline-content:after {
    content: "";
    position: absolute;
    top: 20px;
    height: 16px;
    width: 16px;
    background: #1a233a;
}
.timeline-row .timeline-content:before {
    content: "";
    position: absolute;
    top: 20px;
    right: -49px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    z-index: 10;
    background: #272e48;
    border: 2px dashed #4b546f;
}
.timeline-row .timeline-content h4 {
    margin: 0 0 20px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 150%;
}
.timeline-row .timeline-content p {
    margin-bottom: 30px;
    line-height: 150%;
}
.timeline-row .timeline-content i {
    font-size: 1.2rem;
    line-height: 100%;
    padding: 15px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    background: #272e48;
    margin-bottom: 10px;
    display: inline-block;
}
.timeline-row .timeline-content .thumbs {
    margin-bottom: 20px;
    display: flex;
}
.timeline-row .timeline-content .thumbs img {
    margin: 5px;
    max-width: 60px;
}
.timeline-row .timeline-content .badge {
    color: #ffffff;
    background: linear-gradient(120deg, #00b5fd 0%, #0047b1 100%);
}
.timeline-row:nth-child(even) .timeline-content {
    margin-left: 40px;
    text-align: left;
}
.timeline-row:nth-child(even) .timeline-content:after {
    left: -8px;
    right: initial;
    border-bottom: 0;
    border-left: 0;
    transform: rotate(-135deg);
}
.timeline-row:nth-child(even) .timeline-content:before {
    left: -52px;
    right: initial;
}
.timeline-row:nth-child(odd) {
    padding-left: 0;
    padding-right: 50%;
}
.timeline-row:nth-child(odd) .timeline-time {
    right: auto;
    left: 50%;
    text-align: left;
    margin-right: 0;
    margin-left: 20px;
}
.timeline-row:nth-child(odd) .timeline-content {
    margin-right: 40px;
}
.timeline-row:nth-child(odd) .timeline-content:after {
    right: -8px;
    border-left: 0;
    border-bottom: 0;
    transform: rotate(45deg);
}

@media (max-width: 992px) {
    .timeline {
        padding: 15px;
    }
    .timeline:after {
        border: 0;
    }
    .timeline .timeline-row:nth-child(odd) {
        padding: 0;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-time {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 0 10px 0;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-content {
        margin: 0;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-content:before {
        display: none;
    }
    .timeline .timeline-row:nth-child(odd) .timeline-content:after {
        display: none;
    }
    .timeline .timeline-row:nth-child(even) {
        padding: 0;
    }
    .timeline .timeline-row:nth-child(even) .timeline-time {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 0 10px 0;
        text-align: left;
    }
    .timeline .timeline-row:nth-child(even) .timeline-content {
        margin: 0;
    }
    .timeline .timeline-row:nth-child(even) .timeline-content:before {
        display: none;
    }
    .timeline .timeline-row:nth-child(even) .timeline-content:after {
        display: none;
    }
}