@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
	margin: 0;
	background-color: #eef2f1 !important;
	font-family: 'Poppins', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none !important;  /* Firefox */
	overscroll-behavior: none !important;
	width: '100vw'
}

/* Vertical scrollbar */
body::-webkit-scrollbar {
	width: 0;
  }
  
body::-webkit-scrollbar-thumb {
	background-color: transparent;
}
  
/* Horizontal scrollbar (optional, if needed) */
body::-webkit-scrollbar:horizontal {
	height: 0;
}
  
body::-webkit-scrollbar-thumb:horizontal {
	background-color: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
	display: none !important;
}

code {
	font-family: 'Poppins', sans-serif !important;
}
